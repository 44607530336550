import { createApp } from 'vue';
import { createAuth0 } from '@auth0/auth0-vue';
import { apolloProvider } from '@/graphql/client';
import moment from 'moment-timezone';
import vClickOutside from 'click-outside-vue3';
import App from './App.vue';
import router from './router';
import store from './store';
import Locale from './mixins/Locale';
import Format from './mixins/Format';
import CommonMixin from '@/mixins/Common';
import i18n from './i18n';
import VueNumber from 'vue-number-animation';
import JsonExcel from "vue-json-excel3";
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBell,
  faEllipsisV,
  faPencil,
  faTrash,
  faList,
  faFloppyDisk,
  faAdd,
  faRemove,
  faUserSecret,
  faPowerOff,
  faTemperatureThreeQuarters,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { plugin, defaultConfig } from '@formkit/vue';
import { generateClasses } from '@formkit/themes';
import VueGtag from 'vue-gtag';
import VueGoogleMaps from '@fawmi/vue-google-maps';

//add icons
library.add(faBell);
library.add(faEllipsisV);
library.add(faPencil);
library.add(faTrash);
library.add(faFloppyDisk);
library.add(faAdd);
library.add(faRemove);
library.add(faUserSecret);
library.add(faPowerOff);
library.add(faTemperatureThreeQuarters);
library.add(faList);
//styles
import './assets/css/tailwind.css';

// conditionally load styles

if (process.env.VUE_APP_USER === 'brainbox') {
  require('@/assets/styles/brainbox.stylus');
} else if (process.env.VUE_APP_USER === 'abb') {
  require('@/assets/styles/brainbox.stylus');
}

const marty = createApp(App);
marty.config.globalProperties.$moment = moment;

marty
  .use(apolloProvider)
  .use(
    createAuth0({
      domain: process.env.VUE_APP_AUTH0_DOMAIN,
      client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
      redirect_uri: process.env.VUE_APP_AUTH0_CALLBACK_URL,
      audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    })
  )
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyD6mYea6iyZDuYgnX7xSYuPDtZyy_uaLNY',
      libraries: 'places',
    },
  })
  .component('downloadCsv', JsonExcel)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(plugin, defaultConfig)
  .use(VueNumber)
  .use(i18n)
  .use(vClickOutside)
  .use(store)
  .use(router)
  .mixin(CommonMixin)
  .mixin(Locale)
  .mixin(Format)
  .use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_GA_TAG },
    },
    router
  )
  .mount('#app');
